import moment from "moment/moment";
import "moment/locale/es";
moment.locale("es");

function dateMonthValueFormatter(value) {
  if (!value) {
    return value;
  }
  return moment(value).format("MMMM YYYY");
}

function dateValueFormatter(value) {
  if (!value) {
    return value;
  }
  // Date takes 10 times as much
  return moment(value).format("L");
}

function dateValueSetter(value, row, k) {
  if (!value) {
    row[k.field] = null;
    return { ...row };
  }
  // moment here is 20% slower than simple using toISOString
  row[k.field] = moment(value).format("YYYY-MM-DD");
  return { ...row };
}

function dtimeValueFormatter(value) {
  if (!value) {
    return value;
  }
  // Date takes 8 times as much
  return moment(value).format("L LTS");
}

function dtimeValueGetter(value) {
  if (!value) {
    return value;
  }
  return new Date(value);
}

function dtimeValueSetter(value, row, k) {
  if (!value) {
    row[k.field] = null;
    return { ...row };
  }
  // moment here is 30% slower than simple using toISOString
  row[k.field] = value.toISOString();
  return { ...row };
}

function intervalCellRender(item) {
  if (item != null && typeof item == "string") {
    try {
      let durationMinutes = Math.round(moment.duration(item).asMinutes());
      item = durationMinutes;
    } catch (e) {
      console.log(e);
    }
  }
  return item;
}

const intervalSetter = (value, row, k) => {
  if (value === "" || value == null) {
    value = 0;
  }
  row[k.field] = `PT${value}M`;

  return { ...row };
};

export {
  dateMonthValueFormatter,
  dateValueFormatter,
  dateValueSetter,
  dtimeValueFormatter,
  dtimeValueGetter,
  dtimeValueSetter,
  intervalCellRender,
  intervalSetter,
};
